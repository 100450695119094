import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts/layout';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import '../styles';
import Section from '../components/Section/Section';
import ContentWrapper from '../components/ContentWrapper/ContentWrapper';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import PolicyBody from '../components/PolicyBody/PolicyBody';
import CookieBar from '../components/CookieBar/CookieBar';
import LastUpdateDate from '../components/LastUpdateDate/LastUpdateDate';
import SEO from '../components/SEO';

const Page = ({ pageContext, data }) => {
  const { title, last_update, text, seo } = data.content.frontmatter;

  const crumbs = useMemo(
    () => [
      { title: 'Home', href: '/' },
      { title, href: null },
    ],
    [title],
  );

  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <Layout
      header={data.header}
      footer={data.footer}
      showHeaderAfterScroll={false}
      slug={pageContext.slug}
    >
      <SEO seo={seo} url={url} />
      <Section>
        <Breadcrumbs crumbs={crumbs} />
        <SectionTitle title={title} />
        <ContentWrapper>
          <LastUpdateDate date={last_update.date} label={last_update.label} />
          <PolicyBody content={text} />
        </ContentWrapper>
      </Section>
      <CookieBar data={data.cookies_bar} />
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query PoliciesPageQuery($id: String!, $language: String!) {
    content: markdownRemark(
      id: { eq: $id }
      frontmatter: { language: { eq: $language } }
    ) {
      frontmatter {
        seo {
          title
          description
          facebookAppID
          image
          keywords
          twitterUsername
        }
        title
        linkUrl
        language
        last_update {
          label
          date
        }
        text
      }
    }
    header: markdownRemark(
      frontmatter: {
        templateKey: { eq: "navbar" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        language
        logo {
          image
          imageAlt
        }
        logoFixed {
          image
          imageAlt
        }

        menuItems {
          label
          linkUrl
          openNewTab
        }
      }
    }

    footer: markdownRemark(
      frontmatter: {
        templateKey: { eq: "footer" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        projectLabel
        termsLabel
        privacyLabel
        language
        logo {
          image
          imageAlt
        }

        description

        followus {
          socialmedia {
            logo
            link
          }
          title
        }

        getInTouch {
          title
          body
        }

        useful_links {
          title
          menu {
            title
            link
            openNewTab
          }
        }

        copyright
      }
    }

    cookies_bar: markdownRemark(
      frontmatter: {
        templateKey: { eq: "cookies_bar" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        language
        title
        message
        acceptButton
        closeButton
      }
    }
  }
`;
