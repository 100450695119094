import React from 'react';
import _ from 'lodash';

import './styles.scss';

const createMarkup = (encodedHtml) => ({
  __html: _.unescape(encodedHtml),
});

const PolicyBody = ({ content }) => (
  <div className="policy-body">
    <div dangerouslySetInnerHTML={createMarkup(content)} />
  </div>
);

export default PolicyBody;
